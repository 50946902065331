import React, { memo, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import '@reactuiutils/horizontal-timeline/timeline.css';
import { Event as TimelineEvent, Subtitle, Timeline, Title, } from '@reactuiutils/horizontal-timeline';
import { PiShovelFill } from "react-icons/pi";
import { GiSaltShaker } from "react-icons/gi";
import { MdHistory } from "react-icons/md";
import { baseURL } from '../constants/routes.js';
import { useAuthContext } from './AuthProvider.js';
import { fetchPostalCodeCoords } from '../resources/fetchPostalCodeInfo.js';
import { METEO_DB_5500m_BOUND_FACTOR, MIN_SNOW_DEPTH_FOR_SALT_USAGE } from '../constants/weather.js';
import { handleTimeFormat } from '../constants/interpolationHelpers.js';
import DeviceDetect from './DeviceDetect.js';
import { Box, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
export const TimelineChart = ({ events, postalCode, isValidPostalCode, startTimeUTC, endTimeUTC }) => {
    function LoadingCircle({ postalCode }) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '3rem',
                    backgroundColor: 'grey.100',
                    p: 2,
                } },
                React.createElement(CircularProgress, { color: "success", size: "2rem" }),
                React.createElement(Typography, { variant: 'h6', sx: { ml: 2 } },
                    "\uD83D\uDD0D Searching the ",
                    postalCode.toUpperCase(),
                    " area"))));
    }
    ;
    if (events === 'pending') {
        return (React.createElement(LoadingCircle, { postalCode: postalCode }));
    }
    const timelineEvents = events.map((event, idx) => {
        if (event.type === 'salt') {
            if (!event.salt_usage_guidelines?.length) {
                throw new Error(`Missing salt guideline in event at time ${event.start_time_in_utc}`);
            }
            if (!event.salt_usage_guidelines[0].time_in_utc) {
                throw new Error(`Missing time_in_utc in salt guideline at time ${event.start_time_in_utc}`);
            }
            ;
            if (typeof event.salt_usage_guidelines[0].salt_usage_in_lbs === 'undefined') {
                throw new Error(`Missing or redundant salt usage guideline in salt guideline ` +
                    `at time ${event.start_time_in_utc}`);
            }
            ;
            if (event.salt_usage_guidelines[0].salt_usage_in_lbs === 0) {
                // excess amount of hours doesn't require salt according to sicops chart
                // https://legacy.horttrades.com/assets/1594473479.SICOPS_chart_feb_2020-web.png
                return null;
            }
            ;
            const formattedLocalTime = handleTimeFormat(event.salt_usage_guidelines[0].time_in_utc, 'MMM DD hh:mm A');
            return (React.createElement(TimelineEvent, { color: "blue", key: idx, icon: GiSaltShaker },
                React.createElement(Title, null,
                    "Salt ",
                    event.salt_usage_guidelines[0].salt_usage_in_lbs,
                    " lbs/1000 sq.ft."),
                React.createElement(Subtitle, null,
                    "\u23F0 ",
                    formattedLocalTime)));
        }
        else {
            const formattedLocalTime = handleTimeFormat(event.start_time_in_utc, 'MMM DD hh:mm A');
            return (React.createElement(TimelineEvent, { color: "red", key: idx, icon: PiShovelFill },
                React.createElement(Title, null, "Plow"),
                React.createElement(Subtitle, null,
                    "\u23F0 ",
                    formattedLocalTime)));
        }
    });
    const filteredTimelineEvents = timelineEvents.filter((event) => event !== null);
    const guidelinesTitle = (dayjs(startTimeUTC).add(10, 'minute').isBefore(dayjs())) ?
        `Last guideline for ${postalCode.toUpperCase()} on ` :
        `Guidelines for ${postalCode.toUpperCase()} for next 48 hours`;
    const emptyGuidelinesMessage = (dayjs(startTimeUTC).add(10, 'minute').isBefore(dayjs())) ?
        `No salting guidelines found necessary for ${postalCode.toUpperCase()} in last 14 days` :
        `No salting found necessary for ${postalCode.toUpperCase()} in the next 48 hours.`;
    return (React.createElement(React.Fragment, null, isValidPostalCode && (filteredTimelineEvents.length > 0 ? (React.createElement(Timeline, { title: guidelinesTitle }, filteredTimelineEvents)) : (React.createElement("div", { style: {
            textAlign: 'center',
            fontSize: '18px',
            color: '#D32F2F',
            fontWeight: '500',
            marginTop: '20px',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            backgroundColor: '#FFF9F9'
        } }, emptyGuidelinesMessage)))));
};
function SaltUsageOptions() {
    const authContext = useAuthContext();
    const [events, setEvents] = useState([]);
    const [postalCode, setPostalCode] = useState('');
    const [startTimeUTC, setStartTimeUTC] = useState(dayjs().utc().toISOString());
    const [endTimeUTC, setEndTimeUTC] = useState(dayjs().utc().add(2, 'day').toISOString());
    const { isMobile, isTablet } = DeviceDetect();
    const isDesktop = !isMobile && !isTablet;
    const hasPostalCodeValidCoords = useCallback(() => {
        const regex = /^[A-Za-z]\d[A-Za-z]$/;
        let coords;
        if (!regex.test(postalCode)) {
            return false;
        }
        try {
            coords = fetchPostalCodeCoords(postalCode.toUpperCase());
        }
        catch (e) {
            console.error(`Postal code is valid input but ${postalCode} was not found!`);
            return false;
        }
        return coords;
    }, [postalCode]);
    useEffect(() => {
        const eventSegments = document.querySelectorAll('.min-w-40');
        const eventSegmentRatio = Math.floor(100 / eventSegments.length);
        eventSegments.forEach(eventSegment => {
            eventSegment.style.maxWidth = `${eventSegmentRatio}vw`;
        });
        const titles = document.querySelectorAll('.text-xl');
        titles.forEach(title => {
            if (isDesktop) {
                title.style.fontSize = '1.25rem';
            }
            else {
                title.style.fontSize = '1rem';
            }
        });
        const subtitles = document.querySelectorAll('.text-xs');
        subtitles.forEach(subtitle => {
            if (isDesktop) {
                subtitle.style.fontSize = '1rem';
            }
            else {
                subtitle.style.fontSize = '0.75rem';
            }
        });
        const icons = document.querySelectorAll('.absolute');
        icons.forEach(icon => {
            icon.style.top = 'calc(23% - clamp(20px, 1vw, 40px))';
            icon.style.height = '40px';
            icon.style.width = '40px';
        });
    }, [events, isDesktop]);
    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (!authContext.currentUser || !authContext.idToken) {
            return;
        }
        let coords = hasPostalCodeValidCoords();
        if (!coords)
            return;
        const coordsStr = `${coords[0].toFixed(2)}_${coords[1].toFixed(2)}`;
        const weatherEventsRequest = {
            startTime: startTimeUTC,
            endTime: endTimeUTC,
            locations: [{ lat: coords[0], lng: coords[1] }],
            boundFactor: METEO_DB_5500m_BOUND_FACTOR,
            snowDepthTrigger: MIN_SNOW_DEPTH_FOR_SALT_USAGE,
        };
        async function fetchEvents() {
            try {
                setEvents('pending');
                const response = await axios.post(`${baseURL}/salt-usage-estimate`, {
                    postalCode,
                    coordsStr,
                    weatherEventsRequest,
                    uid: authContext.currentUser.uid,
                    idToken: authContext.idToken,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.status === 404) {
                    setEvents([]);
                }
                else if (response.status === 200) {
                    setEvents(response.data);
                }
                else {
                    console.error(`Failed to fetch events for postal code ${postalCode}`);
                }
            }
            catch (e) {
                console.error(`Failed to fetch events for postal code ${postalCode}: ${e}`);
            }
        }
        ;
        fetchEvents();
    }, [authContext.currentUser, authContext.idToken, hasPostalCodeValidCoords, postalCode, startTimeUTC, endTimeUTC]);
    useEffect(() => {
        if (!hasPostalCodeValidCoords())
            return;
        if (startTimeUTC && endTimeUTC) {
            handleSubmit(new Event('submit'));
        }
    }, [postalCode, startTimeUTC, endTimeUTC, handleSubmit, hasPostalCodeValidCoords]);
    const handleInvalidPostalCode = () => postalCode.length > 0 && !hasPostalCodeValidCoords();
    return (React.createElement("div", { style: {
            maxWidth: '600px', // limit container width (helps on large screens)
            margin: '0 auto', // center the container
            padding: '1rem', // add spacing around
        } },
        React.createElement("h1", { style: { textAlign: 'center' } }, "Salt Usage Guidelines"),
        React.createElement("form", { onSubmit: handleSubmit, style: {
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem', // space between elements
                marginBottom: '1rem',
            } },
            React.createElement(Typography, { variant: 'body1' }, "Enter first three characters of your postal code:"),
            React.createElement(TextField, { id: "postalCode", type: "text", variant: "outlined", placeholder: "e.g., M3A", value: postalCode, onChange: (e) => {
                    setPostalCode(e.target.value);
                    setStartTimeUTC(dayjs().utc().toISOString());
                    setEndTimeUTC(dayjs().utc().add(2, 'day').toISOString());
                }, error: handleInvalidPostalCode(), helperText: handleInvalidPostalCode() ?
                    "Please enter a valid postal code, like M3A" :
                    "", sx: {
                    '& .MuiInputBase-input': {
                        fontSize: '1rem',
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-error fieldset': {
                            borderColor: 'red',
                        },
                    },
                }, fullWidth: true, InputProps: {
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(IconButton, { disabled: handleInvalidPostalCode(), onClick: () => {
                                setPostalCode(postalCode);
                                const LOOKBEHIND_IN_DAYS = 7 * 2;
                                const lookBehindDate = dayjs().utc()
                                    .subtract(LOOKBEHIND_IN_DAYS, 'day').toISOString();
                                setStartTimeUTC(lookBehindDate);
                                setEndTimeUTC(dayjs().utc().toISOString());
                            }, edge: "end", "aria-label": "fetch history", sx: { paddingRight: '0.25rem' } },
                            React.createElement(MdHistory, { size: 40 })))),
                } })),
        React.createElement("div", null,
            React.createElement("h3", { style: { textAlign: 'center' } }, "Demo presets (internal use)"),
            React.createElement("div", { style: { display: 'flex', justifyContent: 'space-around', gap: '5px' } },
                React.createElement("button", { onClick: (e) => {
                        setPostalCode('L6W');
                        setStartTimeUTC('2025-01-01T13:00:00Z');
                        setEndTimeUTC('2025-01-02T13:00:00Z');
                    }, style: {
                        padding: '0.8rem',
                        fontSize: '1rem',
                        cursor: 'pointer',
                        backgroundColor: '#007BFF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s',
                    }, onMouseOver: (e) => e.currentTarget.style.backgroundColor = '#0056b3', onMouseOut: (e) => e.currentTarget.style.backgroundColor = '#007BFF' }, "L6W | 2025-01-02"),
                React.createElement("button", { onClick: (e) => {
                        setPostalCode('M3A');
                        setStartTimeUTC('2025-01-01T13:00:00Z');
                        setEndTimeUTC('2025-01-02T13:00:00Z');
                    }, style: {
                        padding: '0.8rem',
                        fontSize: '1rem',
                        cursor: 'pointer',
                        backgroundColor: '#007BFF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s',
                    }, onMouseOver: (e) => e.currentTarget.style.backgroundColor = '#0056b3', onMouseOut: (e) => e.currentTarget.style.backgroundColor = '#007BFF' }, "M3A | 2025-01-02"),
                React.createElement("button", { onClick: (e) => {
                        setPostalCode('N2H');
                        setStartTimeUTC('2025-01-01T13:00:00Z');
                        setEndTimeUTC('2025-01-02T13:00:00Z');
                    }, style: {
                        padding: '0.8rem',
                        fontSize: '1rem',
                        cursor: 'pointer',
                        backgroundColor: '#007BFF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s',
                    }, onMouseOver: (e) => e.currentTarget.style.backgroundColor = '#0056b3', onMouseOut: (e) => e.currentTarget.style.backgroundColor = '#007BFF' }, "N2H | 2025-01-02"))),
        React.createElement("hr", { style: {
                color: '#343a40', // dark grey color
                backgroundColor: '#343a40', // dark grey color
                height: '2px',
                border: 'none',
                margin: '2rem 0' // adds significant spacing above and below the divider
            } }),
        "            ",
        React.createElement("div", { style: {
                width: '100%',
                // Optionally add some responsiveness
                overflowX: 'auto',
            } },
            React.createElement(TimelineChart, { postalCode: postalCode, isValidPostalCode: hasPostalCodeValidCoords() !== false, startTimeUTC: startTimeUTC, endTimeUTC: endTimeUTC, events: events }))));
}
const MemoizedSaltUsageOptions = memo(SaltUsageOptions);
export default MemoizedSaltUsageOptions;
