export const PRECIPITATION_TYPE = {
    'NONE': 0,
    'RAIN': 1,
    'RAIN_AND_SNOW_MIXED': 2,
    'SNOW': 3,
    'SLEET': 4,
    'FREEZING_RAIN': 5,
    'HAIL': 6,
};
export const METEO_DB_1100m_PRECISION_IN_DECIMALS = 2;
export const METEO_DB_1100m_BOUND_FACTOR = (1 / 10 ** METEO_DB_1100m_PRECISION_IN_DECIMALS);
export const METEO_DB_5500m_BOUND_FACTOR = 0.05;
// export const DEFAULT_AREA_TO_PLOW_IN_SQUARE_METERS = 0;
// export const DEFAULT_TIME_REQUIRED_TO_PLOW_IN_SECONDS = 0;
export const MIN_SNOW_DEPTH_FOR_SALT_USAGE = 0.5;
export const MAX_SNOW_DEPTH_WITHOUT_PLOW_IN_CM = 2.5;
export const PAVEMENT_TEMP_UPPER_BOUND_IN_C = -1;
export const PAVEMENT_TEMP_LOWER_BOUND_IN_C = -7;
export const BSU = 5; // BASELINE_SALT_USAGE_IN_LBS
export const MAX_TIME_BETWEEN_SALT_EVENTS_FOR_MERGE_IN_MINS = 4 * 60;
export const saltChart = [
    { snowDepth: 0.5, temp: -7, saltAmounts: [85, 70, 55, 45, 30, 15, BSU, BSU] },
    { snowDepth: 0.5, temp: -5, saltAmounts: [75, 60, 45, 30, 15, BSU, BSU, BSU] },
    { snowDepth: 0.5, temp: -3, saltAmounts: [60, 45, 30, 15, BSU, BSU, BSU, BSU] },
    { snowDepth: 0.5, temp: -1, saltAmounts: [45, 30, 15, BSU, BSU, BSU, BSU, BSU] },
    { snowDepth: 0.5, temp: 0, saltAmounts: [40, 25, 10, BSU, BSU, BSU, BSU, BSU] },
    { snowDepth: 1.0, temp: -7, saltAmounts: [90, 75, 60, 45, 30, 20, 5, BSU] },
    { snowDepth: 1.0, temp: -5, saltAmounts: [75, 60, 50, 35, 20, 5, BSU, BSU] },
    { snowDepth: 1.0, temp: -3, saltAmounts: [65, 50, 35, 20, 5, BSU, BSU, BSU] },
    { snowDepth: 1.0, temp: -1, saltAmounts: [50, 35, 20, 5, BSU, BSU, BSU, BSU] },
    { snowDepth: 1.0, temp: 0, saltAmounts: [40, 30, 15, BSU, BSU, BSU, BSU, BSU] },
    { snowDepth: 1.5, temp: -7, saltAmounts: [95, 80, 65, 50, 35, 20, 10, BSU] },
    { snowDepth: 1.5, temp: -5, saltAmounts: [80, 65, 50, 35, 25, 10, BSU, BSU] },
    { snowDepth: 1.5, temp: -3, saltAmounts: [65, 55, 40, 25, 10, BSU, BSU, BSU] },
    { snowDepth: 1.5, temp: -1, saltAmounts: [55, 40, 25, 10, BSU, BSU, BSU, BSU] },
    { snowDepth: 1.5, temp: 0, saltAmounts: [45, 30, 15, 5, BSU, BSU, BSU, BSU] },
    { snowDepth: 2.0, temp: -7, saltAmounts: [100, 85, 70, 55, 40, 25, 10, BSU] },
    { snowDepth: 2.0, temp: -5, saltAmounts: [85, 70, 55, 40, 25, 10, BSU, BSU] },
    { snowDepth: 2.0, temp: -3, saltAmounts: [70, 55, 40, 30, 15, BSU, BSU, BSU] },
    { snowDepth: 2.0, temp: -1, saltAmounts: [55, 45, 30, 15, BSU, BSU, BSU, BSU] },
    { snowDepth: 2.0, temp: 0, saltAmounts: [50, 35, 20, 5, BSU, BSU, BSU, BSU] },
    { snowDepth: 2.5, temp: -7, saltAmounts: [105, 90, 75, 60, 45, 30, 15, BSU] },
    { snowDepth: 2.5, temp: -5, saltAmounts: [90, 75, 60, 45, 30, 15, BSU, BSU] },
    { snowDepth: 2.5, temp: -3, saltAmounts: [75, 60, 45, 30, 15, 5, BSU, BSU] },
    { snowDepth: 2.5, temp: -1, saltAmounts: [60, 45, 35, 20, 5, BSU, BSU, BSU] },
    { snowDepth: 2.5, temp: 0, saltAmounts: [55, 40, 25, 10, BSU, BSU, BSU, BSU] },
];
