import React, { useState, useCallback, useEffect, useRef } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import { TextField, Button, Box, IconButton, Stack, Typography, Card, CardContent, Divider, CardActions } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider.js';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker.js';
import Grid from '@mui/material/Grid/Grid.js';
import { METEO_DB_1100m_BOUND_FACTOR } from '../constants/weather.js';
import { useFormContext } from "./VisitFormProvider.js";
import { useAppDispatch, useAppSelector } from "../redux/hooks.js";
import { AddIcon, EditIcon, CheckIcon, DeleteIcon } from '../constants/formIcons.js';
import { convertToISO8601 } from "../constants/interpolationHelpers.js";
import processWeatherEventsResponse from "../resources/processWeatherEvents.js";
import { handleWeatherEventsResponse } from "../redux/locationSlice.js";
import { useAuthContext } from "./AuthProvider.js";
dayjs.extend(utc);
dayjs.extend(timezone);
const ALLOWED_BUFFER_FOR_TRAVELLING = 1 + 0.05;
const parseDuration = (input) => {
    const hrRegex = /([0-9]+)\s*(?:hr|hour|h)/i;
    const minRegex = /([0-9]+)\s*(?:min|minutes|m)/i;
    const hoursMatch = input.match(hrRegex);
    const minutesMatch = input.match(minRegex);
    let hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    let minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
    if (!hoursMatch && !minutesMatch) {
        hours = 0;
        minutes = 0;
    }
    return { hours, minutes };
};
function FormRow({ row, onError }) {
    // Flow:
    // 1. Load values from row props into state variables.
    // 2. update state variables with input.
    // 3. once isDone, then either update formContext.rows or update visits directly with updated shipment
    if (!row.value.pickups.length ||
        !row.value.pickups[0].timeWindows.length ||
        !row.value.pickups[0].timeWindows[0].startTime ||
        !row.value.pickups[0].timeWindows[0].endTime ||
        !row.value.pickups[0].duration ||
        !row.value.pickups[0].label ||
        !row.value.pickups[0].arrivalLocation) {
        throw new Error(`Visit missing pickups and/or time windows, duration, label, arrival coords`);
    }
    if (!row.value.pickups[0].arrivalLocation ||
        !Object.keys(row.value.pickups[0].arrivalLocation).length) {
        throw new Error(`Visit missing arrival location co-ordinates`);
    }
    const numberOfVehicles = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.vehicles?.length);
    if (!numberOfVehicles) {
        throw new Error(`Need to add vehicles for the simulation: currently set to 0`);
    }
    const formContext = useFormContext();
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeFormatType = useAppSelector((state) => state.user.timeFormat);
    // 1. Reading Row prop and updating state variables
    const globalStartTime = dayjs(formContext.additionalContext.globalStartTimeISO8601);
    const globalEndTime = dayjs(formContext.additionalContext.globalEndTimeISO8601);
    function getDurationFromProps() {
        const savedDuration = row.value.pickups[0].duration;
        if (typeof savedDuration === 'string') {
            function formatSecondsToTime(secondsStr) {
                const seconds = parseInt(secondsStr.slice(0, -1), 10);
                const hours = Math.floor(seconds / 3600);
                const minutes = Math.floor((seconds % 3600) / 60);
                return `${hours} hr ${minutes} min`;
            }
            return formatSecondsToTime(savedDuration);
        }
        ;
        return '';
    }
    ;
    function getAddressFromProps() {
        try {
            const serializedLabel = row.value.pickups[0].label.split('///');
            const address = serializedLabel[0];
            return address ? address : '';
        }
        catch (e) {
            throw new Error(`Was not able to parse address from pickup label: ${row.value.pickups[0].label}`);
        }
    }
    ;
    function getRevenueFromProps() {
        try {
            if (!row.value.costsPerVehicle.length) {
                // If cost has not been set, then no revenue either
                return '0.00';
            }
            const serializedLabel = row.value.pickups[0].label.split('///');
            const savedRevenue = serializedLabel[2];
            return savedRevenue ? savedRevenue : '0.00';
        }
        catch (e) {
            throw new Error(`Was not able to parse revenue from pickup label: ${row.value.pickups[0].label}`);
        }
    }
    function getDeadlineFromProps() {
        // TODO: Get rid of since globalEndTime is already declared.
        return dayjs(row.value.pickups[0].timeWindows[0].endTime);
    }
    ;
    const textFieldRef = useRef(null);
    // TODO: Block checking off isDone if false until they type in a valid location
    const [deadline, setDeadline] = useState(getDeadlineFromProps());
    const [duration, setDuration] = useState(getDurationFromProps());
    const [formRevenue, setFormRevenue] = useState(getRevenueFromProps());
    const [formAddress, setFormAddress] = useState(getAddressFromProps());
    const [locationCoords, setLocationCoords] = useState(row.value.pickups[0].arrivalLocation);
    // Error validation state variables
    const [isInvalidDeadline, setIsInvalidDeadline] = useState(false);
    const [isInvalidDuration, setIsInvalidDuration] = useState(false);
    const [isExcessiveDuration, setIsExcessiveDuration] = useState(false);
    const [isEligibleLocation, setIsEligibleLocation] = useState(true);
    const [isInvalidRevenue, setIsInvalidRevenue] = useState(false);
    // 2. Update state variables with user input
    const handleTextFieldChange = useCallback((newTextFieldValue) => {
        // Solely handles autocomplete changes for location address and coords
        setFormAddress(newTextFieldValue);
        if (textFieldRef.current) {
            const getAutocompleteAddress = function (address, location, isEligibleLocation) {
                setFormAddress(address);
                setLocationCoords({
                    latitude: location.lat(),
                    longitude: location.lng(),
                });
                setIsEligibleLocation(isEligibleLocation);
            };
            formContext.additionalContext.fetchAddressSuggestions(textFieldRef, getAutocompleteAddress);
        }
    }, [formContext.additionalContext]);
    const handleRevenue = useCallback((inputRevenue) => {
        if (inputRevenue === '') {
            setFormRevenue('0.00');
        }
        setFormRevenue(inputRevenue);
    }, []);
    const finalizeRevenue = useCallback(() => {
        const parsedRevenue = parseFloat(formRevenue);
        if (isNaN(parsedRevenue)) {
            setFormRevenue('0.00');
        }
        else {
            setFormRevenue(parsedRevenue.toFixed(2));
        }
    }, [formRevenue]);
    const handleDurationBlur = () => {
        const { hours, minutes } = parseDuration(duration);
        const formattedDuration = `${hours} hr ${minutes} min`;
        setDuration(formattedDuration);
    };
    // 3. Update Rows with changed row
    const handleRowDelete = useCallback(() => {
        formContext.deleteRow(row.id);
    }, [formContext, row.id]);
    const handleRowUpdate = useCallback(() => {
        const parsedRevenue = parseFloat(formRevenue);
        if (parsedRevenue < 0) {
            setIsInvalidRevenue(true);
            return;
            // throw new Error(`Revenue must be above zero!: ${formRevenue}`);
        }
        let shipmentCost;
        if (parsedRevenue === 0) {
            shipmentCost = 2;
        }
        else {
            shipmentCost = parseFloat((1 / parsedRevenue).toFixed(6));
        }
        ;
        const { hours, minutes } = parseDuration(duration);
        if (!row.isDone) {
            if (!locationCoords.latitude || !locationCoords.longitude || !formAddress) {
                setIsEligibleLocation(false);
                return;
                // throw new Error(`Missing location details!: ${formAddress}: ${JSON.stringify(locationCoords)}`);
            }
            if (deadline.isBefore(globalStartTime) || deadline.isAfter(globalEndTime)) {
                setIsInvalidDeadline(true);
                return;
            }
            if (!hours && !minutes) {
                setIsInvalidDuration(true);
                return;
            }
            function computeExcessDuration() {
                const visitDuration = (hours * 3600) + (minutes * 60);
                const allowedDuration = deadline.diff(globalStartTime, 'second');
                if (allowedDuration <= ALLOWED_BUFFER_FOR_TRAVELLING * visitDuration) {
                    setIsExcessiveDuration(true);
                    return true;
                }
                else {
                    setIsExcessiveDuration(false);
                    return false;
                }
                ;
            }
            ;
            // Check: Start time + duration of the job must not exceed the deadline set by user.  
            if (computeExcessDuration())
                return;
        }
        function constructLabel() {
            const coordsStr = `${locationCoords.latitude}_${locationCoords.longitude}`;
            return `${formAddress}///${coordsStr}///${formRevenue}`;
        }
        ;
        const updatedPickup = {
            arrivalLocation: locationCoords,
            duration: `${(hours * 3600) + (minutes * 60)}s`,
            label: constructLabel(),
            timeWindows: [{
                    startTime: convertToISO8601(globalStartTime),
                    endTime: convertToISO8601(deadline),
                }],
        };
        const updatedShipment = {
            costsPerVehicle: new Array(numberOfVehicles).fill(shipmentCost),
            // isContinuous: false,
            pickups: [updatedPickup]
        };
        const updatedRow = {
            id: row.id,
            value: updatedShipment,
            isDone: !row.isDone,
        };
        formContext.updateRow(updatedRow);
    }, [formContext, row.id, row.isDone, deadline, duration, formAddress,
        formRevenue, globalStartTime, globalEndTime, locationCoords, numberOfVehicles]);
    useEffect(() => {
        if (row.isDone) {
            if (deadline.isBefore(globalStartTime) || deadline.isAfter(globalEndTime)) {
                setIsInvalidDeadline(true);
            }
        }
        ;
    }, [row.isDone, deadline, globalStartTime, globalEndTime]);
    return (React.createElement(Card, { key: row.id, variant: "outlined", sx: { mb: 2 } },
        React.createElement(CardContent, null, row.isDone ? (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Typography, { variant: "h6" }, formAddress)),
            React.createElement(Divider, { sx: { width: '100%', my: 1 } }),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', mb: 1 } },
                    React.createElement(Typography, { variant: "body2", color: "textSecondary" }, "Deadline:"),
                    React.createElement(Typography, { variant: "body2" }, deadline?.tz(tz).format(timeFormatType))),
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', mb: 1 } },
                    React.createElement(Typography, { variant: "body2", color: "textSecondary" }, "Duration:"),
                    React.createElement(Typography, { variant: "body2" }, duration)),
                React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', mb: 1 } },
                    React.createElement(Typography, { variant: "body2", color: "textSecondary" }, "Revenue:"),
                    React.createElement(Typography, { variant: "body2" }, formRevenue)),
                isInvalidDeadline &&
                    React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', mb: 1 } },
                        React.createElement(Typography, { variant: "body2", color: 'red' },
                            "The deadline should be within ",
                            globalStartTime.format(timeFormatType),
                            " and ",
                            globalEndTime.format(timeFormatType)))))) : (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(TextField, { fullWidth: true, error: !isEligibleLocation, helperText: !isEligibleLocation ? 'Location is not presently eligible, try another' : null, inputRef: textFieldRef, value: formAddress, onChange: (e) => handleTextFieldChange(e.target.value), disabled: row.isDone, placeholder: "Enter visit location" })),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                    React.createElement(DateTimePicker, { slotProps: {
                            textField: {
                                error: isInvalidDeadline,
                                helperText: isInvalidDeadline
                                    ? `Deadline should be within ${globalStartTime.format(timeFormatType)} - ${globalEndTime.format(timeFormatType)}`
                                    : null,
                            },
                        }, label: "Choose deadline for the job", value: deadline, onChange: (e) => {
                            setDeadline(e);
                            setIsInvalidDeadline(false);
                        }, minDateTime: globalStartTime, maxDateTime: globalEndTime }))),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(TextField, { fullWidth: true, error: isInvalidDuration || isExcessiveDuration, helperText: isInvalidDuration ? 'Duration must be at least 1 min long' : isExcessiveDuration ? 'Deadline is too soon for the duration provided, choose an earlier start time in Step 2' : null, label: "Enter Duration", variant: "outlined", placeholder: "55 min or 1 hr 20 min", value: duration, onChange: (e) => {
                        setDuration(e.target.value);
                        setIsInvalidDuration(false);
                        setIsExcessiveDuration(false);
                    }, onBlur: handleDurationBlur })),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(TextField, { fullWidth: true, error: isInvalidRevenue, helperText: isInvalidRevenue ? 'Revenue must be $0.00 or greater' : null, label: "Enter expected revenue, e.g. $85, $102.50", variant: "outlined", value: formRevenue, onChange: (e) => {
                        handleRevenue(e.target.value);
                        setIsInvalidRevenue(false);
                    }, onBlur: finalizeRevenue }))))),
        React.createElement(Divider, null),
        React.createElement(CardActions, { sx: { justifyContent: 'flex-end' } },
            React.createElement(IconButton, { onClick: handleRowUpdate, color: row.isDone ? 'primary' : 'success' }, row.isDone ? React.createElement(EditIcon, null) : React.createElement(CheckIcon, null)),
            React.createElement(IconButton, { onClick: handleRowDelete },
                React.createElement(DeleteIcon, null)))));
}
export default function VisitForm({ handleNext, handleBack }) {
    const authContext = useAuthContext();
    const formContext = useFormContext();
    const dispatch = useAppDispatch();
    const globalStartTime = dayjs(formContext.additionalContext.globalStartTimeISO8601);
    const globalEndTime = dayjs(formContext.additionalContext.globalEndTimeISO8601);
    const timeFormatType = useAppSelector((state) => state.user.timeFormat);
    const snowDepthTriggerInCm = useAppSelector((state) => state.simulation.snowDepthTrigger);
    const [successfulVisits, setSuccessfulVisits] = useState([]);
    const [showPendingRowsError, setShowPendingRowsError] = useState(false);
    const [incompleteFormError, setIncompleteFormError] = useState(false);
    const [invalidDeadlineError, setInvalidDeadlinesError] = useState(false);
    const [excessDuration, setExcessDuration] = useState(0);
    const [lateETA, setLateETA] = useState('');
    const handleAddRow = () => {
        formContext.addRow();
    };
    const numberOfVehicles = useAppSelector((state) => state.simulation.request?.optimizationRequest?.model.vehicles?.length);
    if (!numberOfVehicles) {
        throw new Error(`Need to add vehicles for the simulation: currently set to 0`);
    }
    useEffect(() => {
        const pendingRows = formContext.rows.filter(row => !row.isDone);
        if (pendingRows.length) {
            // TODO: user might be expecting error to disappear on updating FormRow,
            // not when they hit submit button.
            setShowPendingRowsError(true);
        }
        else {
            setShowPendingRowsError(false);
        }
    }, [formContext.rows]);
    useEffect(() => {
        let hasInvalidDeadlines = false;
        const completedRows = formContext.rows.filter(row => row.isDone);
        if (completedRows.length) {
            completedRows.forEach(row => {
                const deadline = dayjs(row.value.pickups[0].timeWindows[0].endTime);
                if (deadline.isBefore(globalStartTime) || deadline.isAfter(globalEndTime)) {
                    hasInvalidDeadlines = true;
                }
            });
        }
        setInvalidDeadlinesError(hasInvalidDeadlines);
    }, [formContext.rows, globalStartTime, globalEndTime]);
    const handleError = useCallback((hasInvalidDeadlines) => {
        setInvalidDeadlinesError(hasInvalidDeadlines);
    }, []);
    useEffect(() => {
        if (!successfulVisits.length || !authContext.currentUser || !authContext.idToken) {
            return;
        }
        if (!snowDepthTriggerInCm) {
            return;
        }
        const locationsCoords = successfulVisits.map((visit) => {
            if (!visit.pickups.length) {
                throw new Error('Missing pickup from shipment');
            }
            const arrivalLocation = visit.pickups[0].arrivalLocation;
            return { lat: arrivalLocation.latitude, lng: arrivalLocation.longitude };
        });
        const weatherEventsRequest = {
            locations: locationsCoords,
            startTime: formContext.additionalContext.globalStartTimeISO8601,
            endTime: formContext.additionalContext.globalEndTimeISO8601,
            snowDepthTrigger: snowDepthTriggerInCm,
            boundFactor: METEO_DB_1100m_BOUND_FACTOR,
        };
        // Pre-emptively fetch visit weather events for the submit steps
        processWeatherEventsResponse(weatherEventsRequest, authContext.currentUser.uid, authContext.idToken).then((weatherEventsResponse) => {
            if (weatherEventsResponse) {
                dispatch(handleWeatherEventsResponse(weatherEventsResponse));
            }
        }).catch((e) => {
            console.error(`Failed to fetch weather events for ${JSON.stringify(weatherEventsRequest)}: ${e}`);
        });
    }, [successfulVisits, authContext.currentUser, authContext.idToken, dispatch,
        formContext.additionalContext.globalStartTimeISO8601,
        formContext.additionalContext.globalEndTimeISO8601, snowDepthTriggerInCm]);
    const handleSubmit = () => {
        // TODO: Throw error if user tries to submit rows which have 'isDone' set to false.
        // Check 1: check for incomplete rows
        // Check 2: check for unsubmitted rows
        // Check 3: check for total duration of jobs + 5% buffer exceeeding global start and end times
        if (showPendingRowsError) {
            return;
        }
        if (invalidDeadlineError) {
            return;
        }
        const visits = formContext.rows
            .filter(row => row.isDone)
            .map(row => row.value);
        formContext.additionalContext.setVisits(visits);
        if (!visits.length) {
            setIncompleteFormError(true);
            return;
        }
        function computeExcessDuration() {
            const visitsTotalDuration = visits.reduce((sum, visit) => {
                const durationString = visit.pickups[0].duration;
                const duration = parseInt(durationString.slice(0, -1), 10);
                return sum + duration;
            }, 0);
            const globalStartTime = dayjs(formContext.additionalContext.globalStartTimeISO8601);
            const globalEndTime = dayjs(formContext.additionalContext.globalEndTimeISO8601);
            const allowedDuration = globalEndTime.diff(globalStartTime, 'second') * numberOfVehicles;
            let excessDuration = 0;
            if (allowedDuration <= ALLOWED_BUFFER_FOR_TRAVELLING * visitsTotalDuration) {
                excessDuration = (ALLOWED_BUFFER_FOR_TRAVELLING * visitsTotalDuration) - allowedDuration;
                setExcessDuration(excessDuration);
                const lateTime = globalEndTime.add(excessDuration, 'second');
                setLateETA(lateTime.format(timeFormatType));
                return true;
            }
            else {
                setExcessDuration(0);
                return false;
            }
            ;
        }
        ;
        if (computeExcessDuration()) {
            return;
        }
        setSuccessfulVisits(visits);
        handleNext();
        console.log(JSON.stringify(visits, null, 2));
    };
    return (React.createElement("div", { className: "form", style: { width: '100%' } },
        React.createElement(Box, { sx: { width: '100%' } },
            React.createElement(Stack, { spacing: 2, sx: { width: '100%' } }, formContext.rows.map((row) => (React.createElement(FormRow, { key: row.id, row: row, onError: handleError })))),
            showPendingRowsError ?
                React.createElement(Typography, { variant: "body1", color: 'red' }, "Finish pending visit \u2705 or remove it \u26D4\uFE0E") :
                null,
            incompleteFormError ?
                React.createElement(Typography, { variant: "body1", color: 'red' }, "Add a visit to plan a snow removal route") :
                null,
            excessDuration > 0 ?
                React.createElement(Typography, { variant: "body1", color: 'red' },
                    "\u23F3 Insufficient duration to complete all these visits (est. to complete at ",
                    lateETA,
                    ") ") :
                null,
            React.createElement(Stack, { direction: "row", spacing: 2, sx: { mt: 2, justifyContent: 'center' } },
                React.createElement(Button, { onClick: handleAddRow },
                    React.createElement(AddIcon, { color: "primary" })),
                React.createElement(Button, { onClick: handleSubmit, color: "primary", variant: "contained" }, "Save"),
                React.createElement(Button, { onClick: handleBack, color: "secondary" }, "Back")))));
}
