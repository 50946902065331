import axios from 'axios';
import { baseURL } from '../constants/routes.js';
export function parseWeatherEvents(rawWeatherEventsResponse) {
    let weatherEventsResponse = undefined;
    if (rawWeatherEventsResponse && !rawWeatherEventsResponse.weatherEvents?.length) {
        throw new Error(`Received data from /fetch-weather-events but no weather events: ${JSON.stringify(rawWeatherEventsResponse)}`);
    }
    if (rawWeatherEventsResponse && !rawWeatherEventsResponse.contiguousWeatherEvents?.length) {
        throw new Error(`Received data from /fetch-weather-events but no contiguous weather events : ${JSON.stringify(rawWeatherEventsResponse)}`);
    }
    try {
        const weatherEventsMap = new Map(rawWeatherEventsResponse.weatherEvents);
        const contiguousWeatherEventsMap = new Map(rawWeatherEventsResponse.contiguousWeatherEvents);
        const weatherEvents = weatherEventsMap;
        const contiguousWeatherEvents = contiguousWeatherEventsMap;
        weatherEventsResponse = { weatherEvents, contiguousWeatherEvents };
    }
    catch (e) {
        throw new Error(`Unable to convert weatherEvents from response into a map!: ${rawWeatherEventsResponse.weatherEvents}`);
    }
    return weatherEventsResponse;
}
;
export default async function processWeatherEventsResponse(weatherEventsRequest, uid, idToken) {
    let weatherEventsResponse = undefined;
    try {
        const response = await axios.post(`${baseURL}/fetch-weather-events`, {
            ...weatherEventsRequest,
            uid,
            idToken,
        });
        if (response.status === 200) {
            weatherEventsResponse = parseWeatherEvents(response.data);
        }
        ;
        return weatherEventsResponse;
    }
    catch (e) {
        throw new Error(`Failed to fetch weather events for ${JSON.stringify(weatherEventsRequest)}: ${e}`);
    }
}
;
